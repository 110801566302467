<template>
  <div class="view flex flex-d" style="position: relative;">
    <headers :styleSize="'min'" :times="dataTime" languageType="EN"></headers>
    <div class="main flex flex-d  ai-c ">
      <img src="@/assets/speaial_activity_img/imageEn/time_content_title.png" class="time-title" alt="">
      <TitleAndTime languageType="EN" :time="dataTime">
        <img src="@/assets/speaial_activity_img/imageEn/two_title.png" class="title" alt="">
      </TitleAndTime>
      <div ref="twoChartRef" class="charte-viwe"></div>
    </div>
    <Annotation languageType="EN"></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import Counter from '@/components/Big/Counter.vue'
import Annotation from '@/components/Big/Annotation'
import TitleAndTime from '@/components/Big/TitleAndTime'
import * as echarts from 'echarts'
export default {
  components: {
    Headers,
    Counter,
    Annotation,
    TitleAndTime,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      dataTime: '',
      saleNum: '',
    }
  },
  watch: {
    data(newValue, oldValue) {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // console.log('level2', this.data)
      let dataX = []
      let dataY = []
      this.data.forEach((item) => {
        dataX.push(item.time)
        dataY.push((item.sales / 100000000).toFixed(2))
      })
      // this.dataTime = this.$dateFormat(this.data[this.data.length - 1].time)
      let start = this.data[0].time.split('-')[0]
      let end = this.data[this.data.length - 1].time.split('-')[1]
      this.dataTime = `Data collected from ${start} Nov. 10th to ${end} Nov.11th`
      this.setEchart(dataX, dataY)
    },
    setEchart(dataX, dataY) {
      var chartDom = this.$refs['twoChartRef']
      var myChart = echarts.init(chartDom)
      var option
      option = {
        grid: {
          right: '5%',
        },
        xAxis: {
          type: 'category',
          data: dataX,
          axisLabel: {
            color: '#FFFFFF',
            fontSize: 24,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          name: '(Hundred million yuan)',
          nameTextStyle: {
            fontSize: 24,
            color: '#fff',
            align: 'center',
            // fontWeight: 500,
          },
          nameGap: 30,
          axisLabel: {
            color: '#FFFFFF',
            fontSize: 24,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(208, 217, 226, 0.2)',
            },
          },
        },
        series: [
          {
            data: dataY,
            type: 'line',
            smooth: true,
            itemStyle: {
              color: 'rgba(255, 245, 148, 1)',
              shadowBlur: 10,
              shadowColor: 'rgba(255, 255, 255, 1)',
            },
            symbolSize: 10,
            label: {
              show: true,
              color: '#FFFFFF',
              fontSize: 24,
            },
            lineStyle: {
              width: 4,
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 253, 165, 1)', // 渐变起始颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(215, 200, 101, 1)', // 渐变结束颜色
                  },
                ],
              },
            },
          },
        ],
      }

      option && myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/en.scss';
</style>
